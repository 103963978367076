<template>
  <div>
    <a-modal v-model="visible" :footer="null" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">查看</div>
      <div id="printDiv" ref="print">
        <a-form-model ref="form" :model="form.others" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="name" label="名称">
            <a-input disabled v-model="form.others.name" />
          </a-form-model-item>
          <a-form-model-item prop="number" label="编号">
            <a-input disabled v-model="form.others.number" />
          </a-form-model-item>
          <a-form-model-item prop="contact" label="联系人">
            <a-input disabled v-model="form.others.contact" />
          </a-form-model-item>
          <a-form-model-item prop="phone" label="手机号">
            <a-input disabled v-model="form.others.phone" />
          </a-form-model-item>
          <a-form-model-item prop="email" label="邮箱">
            <a-input disabled v-model="form.others.email" />
          </a-form-model-item>
          <a-form-model-item prop="address" label="地址">
            <a-input disabled v-model="form.others.address" />
          </a-form-model-item>
          <a-form-model-item prop="ID_image" label="上传附件">
            <a-upload
                    disabled
                    :action="uploadUrl"
                    list-type="picture-card"
                    :file-list="form.others.ID_image"
                    @preview="handlePreview"
                    @change="handleChange"
                    name="file">
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text"></div>
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item prop="remark" label="备注">
            <a-textarea disabled v-model="form.others.remark" allowClear />
          </a-form-model-item>
        </a-form-model>
      </div>
      <div style="text-align: center">
        <a-button style="margin: 10px 30px" type="primary" v-print="printOptions" @click="printOrder">打印</a-button>
      </div>
    </a-modal>
    <a-modal v-model="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { systemAdd, systemEdit } from "@/api/enterprise";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  name: "FormModal",
  props: ["visible", "form", "clientsClassificationOptions"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      printOptions: {
        id: "printDiv",
        popTitle: "正常打印",
        extraHead: "",
      },
      previewVisible: false,
      previewImage: '',
      uploadUrl: process.env.VUE_APP_NEW_URL + '/comapi/UploadApi',
      levelOptions: [
        { id: "0", name: "0" },
        { id: "1", name: "1" },
        { id: "2", name: "2" },
        { id: "3", name: "3" },
      ],
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { max: 64, message: "超出最大长度 (64)", trigger: "blur" },
        ],
        number: [
          { required: true, message: "请输入编号", trigger: "blur" },
          { max: 32, message: "超出最大长度 (32)", trigger: "blur" },
        ]
      },
      loading: false,
    };
  },
  methods: {
    printOrder() {
      this.$print(this.$refs.print)
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.$set(this.form.others,'ID_image',fileList);
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? systemEdit : systemAdd;
          func(this.form)
            .then(() => {
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
              this.$emit(this.form.id ? "update" : "create");
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
    },
  },
};
</script>

<style scoped></style>
